import React from 'react';
import './styles.css';
import logo from './assets/images/HYDRO-FE_WHITE@4x.png';
import bgVideo from './assets/images/bg-5.mp4';
import BackgroundVideo from './background';


const Home = () => {
  return (
    <div className="home-container">
      <header className="home-header">
      </header>
      <div data-theme="light">
        <main className="home-main">
          <p>Turning the tide in green energy</p>
          {/* <input type="email" placeholder="Enter your email for more info" /> */}
        </main>
      </div>
      <div>
          <BackgroundVideo />
      </div>
      <footer className="home-footer">
       <img src={logo} alt="Hydro Site Logo" />
        <p>&copy; 2023 Hydro Flow Energy Site. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Home;