import React from 'react';
import bgVideo from './assets/images/bg-5.mp4';

const BackgroundVideo = () => {
  return (
    <video autoPlay loop muted className="background-video">
      <source src={bgVideo} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default BackgroundVideo;